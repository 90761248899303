.App_main__RI-JY {
    min-height: 90vh;
}

.App_pointer__C-BIw {
    cursor: pointer;
}

.Footer_miniFooter__209NH {
    height: calc(100% - 40px);
}
.pagination {
    .page-ellipsis {
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        pointer-events: none;
    }
}

.Toastify__toast--info {
    color: white;
    background-color: #1361ff !important;
    border-color: #bdd3ff !important;
}

.Toastify__toast--error {
    color: white;
    background-color: #d41a11;
    border-color: #f3bfbc;
}

.Toastify__toast {
    padding: 1rem 1rem;
    font-family: 'Roboto';
}

.Toastify__toast-body * {
    white-space: pre-line;
}

.react-datepicker {
    width: 328px !important;
}

.html-code {
    background-color: #f0f0f0;
    border:1px solid #dfdfdf;
    overflow-x: auto;
}
